import React from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  Classes,
  OverlayToaster,
  ToastOptions,
  ToastProps,
  Toaster,
  ToasterPosition,
} from "@blueprintjs/core";
import { typedObjectKeys } from "@hex/common";
import { LocalStorageKeys } from "../../hooks/useLocalStorage";
import { getStorageValue } from "../../hooks/useBrowserStorage";
import { getThemeFromName } from "../../hooks/useGetThemeFromName.js";
/**
 * Apply this class to the `className` option of `toaster.show()`
 * in order to opt out of most of our default toast styles in `GlobalStyle.ts`.
 * This is useful when making custom toasts with fancier UIs
 * since many of default styles are implemented with !important and hard to override.
 */
export const HEX_CUSTOM_TOAST_CLASS = "hex-custom-toast";

export const HEX_CUSTOM_BACKGROUND_REFRESH_TOAST_CLASS =
  "hex-background-refresh-toast";

export const ToastIcon = styled.div`
  padding: 0 0 0 15px;

  font-size: 24px;

  /*
  This hides the default close button (which doesn't seem disable-able) in any other way.
  I needed to center the button vertically and decided the only way to do that was either use the
  <Toast /> component and wrap that in css, or do this.
  */
  & ~ .${Classes.BUTTON_GROUP} {
    display: none;
  }
`;

export const ToastBody = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
`;

export const ToastContent = styled.div`
  margin-right: 50px;
`;

export const ToastContentTitle = styled.div`
  margin-bottom: 2px;

  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
`;

export const ToastContentDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

// toaster that does nothing, placeholder until OverlayToaster is asynchronously created
const DefaultToaster: Toaster = {
  show: (_props: ToastProps, _key?: string): string => "",
  dismiss: (_key: string): void => {},
  clear: (): void => {},
  getToasts: (): ToastOptions[] => [],
};

// set of toasters for the various positions, initialized to default impls
const Toasters: { [K in ToasterPosition]: Toaster } = {
  top: DefaultToaster,
  "top-left": DefaultToaster,
  "top-right": DefaultToaster,
  bottom: DefaultToaster,
  "bottom-left": DefaultToaster,
  "bottom-right": DefaultToaster,
};

// initialize the toasters (replace default with functional implementations)
export async function initToasters() {
  const Positions = typedObjectKeys(Toasters);
  const toasters = await Promise.all(
    Positions.map((position) =>
      OverlayToaster.createAsync({ position, usePortal: false }),
    ),
  );
  toasters.forEach((toaster, i) => {
    Toasters[Positions[i]] = toaster;
  });
}

export interface HexToastProps extends ToastProps {
  position?: ToasterPosition;
}

export interface HexToaster {
  show: (props: HexToastProps, key?: string) => string;
  dismiss: Toaster["dismiss"];
  clear: Toaster["clear"];
}

export const HexToasterInstance: HexToaster = {
  show({ position = "top", ...props }, key) {
    // wrap message in a theme provider since the toast is rendered outside the app React tree
    const themeName = getStorageValue(LocalStorageKeys.THEME_NAME, "Local");
    const theme = getThemeFromName(themeName);
    const updatedProps = {
      ...props,
      message: <ThemeProvider theme={theme}>{props.message}</ThemeProvider>,
    };
    if (props.icon) {
      updatedProps.icon = (
        <ThemeProvider theme={theme}>{props.icon}</ThemeProvider>
      );
    }
    if (props.action?.icon && updatedProps.action) {
      updatedProps.action.icon = (
        <ThemeProvider theme={theme}>{props.action.icon}</ThemeProvider>
      );
    }
    if (props.action?.text && updatedProps.action) {
      updatedProps.action.text = (
        <ThemeProvider theme={theme}>{props.action.text}</ThemeProvider>
      );
    }
    return Toasters[position].show(updatedProps, key);
  },
  dismiss(...args) {
    for (const toaster of Object.values(Toasters)) {
      toaster.dismiss(...args);
    }
  },
  clear(...args) {
    for (const toaster of Object.values(Toasters)) {
      toaster.clear(...args);
    }
  },
};

// retained for compatibility with existing usage
export function useToaster(): HexToaster {
  return HexToasterInstance;
}
