import { gql } from "@apollo/client";
import { Intent, PopoverInteractionKind } from "@blueprintjs/core";
import {
  AdvancedComputeKernelOptionsUnionLiteral,
  KernelSize,
  OrgRole,
} from "@hex/common";
import filesize from "filesize";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { KernelSizeConfigV2 } from "../../generated/graphqlTypes.js";
import { HexTooltip } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import { useToggleState } from "../../hooks/useToggleState.js";
import { useHexFlag } from "../../util/useHexFlags.js";
import { ContactAnAdmin } from "../common/ContactAnAdmin.js";
import { Link } from "../common/DocsLink";
import { Picker } from "../common/Picker";
import { FeatureGatePill } from "../feature-gate/FeatureGatePill";
import { FeatureGateToolTip } from "../feature-gate/FeatureGateToolTip";

import {
  useGetKernelSizesForPickerQuery,
  useUserConfirmedPaidComputeQuery,
} from "./KernelSizePicker.generated";
import { PaidComputeConfirmationDialog } from "./PaidComputeConfirmationDialog.js";

gql`
  query GetKernelSizesForPicker {
    getKernelSizesV2 {
      options {
        id
        humanName
        memoryLimit
        cpuLimit
        disabled
        gpuLimit
      }
      default
      isFreeTrial
    }
  }
`;

gql`
  query UserConfirmedPaidCompute {
    me {
      id
      confirmedPaidCompute
    }
  }
`;

const Description = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

interface KernelSizePickerProps {
  currentKernelSize: KernelSize | null;
  onSelect: (newKernelSize: KernelSize) => void;
  disabled?: boolean;
  hideLabel?: boolean; // defaults to false
  smallPicker?: boolean; // defaults to true
  isSignedEmbeddedProject?: boolean; // defaults to false
}

export const KernelSizePicker: React.FunctionComponent<
  KernelSizePickerProps
> = ({
  currentKernelSize,
  disabled,
  hideLabel = false,
  isSignedEmbeddedProject = false,
  onSelect,
  smallPicker = true,
}) => {
  // We want to auto reload the UI on either of these flags changing
  const advancedComputeProfilesToShow = useHexFlag(
    "advanced-compute-profiles-to-show",
  );
  const advancedComputeProfilesToShowString = JSON.stringify(
    advancedComputeProfilesToShow,
  );
  const advancedComputeProfilesToEnable = useHexFlag(
    "advanced-compute-profiles-to-enable",
  );
  const advancedComputeProfilesToEnableString = JSON.stringify(
    advancedComputeProfilesToEnable,
  );
  const kernelPrices = useHexFlag("compute-profile-prices");
  const kernelPricesString = JSON.stringify(kernelPrices);

  const [
    paidComputeConfirmationDialogOpen,
    ,
    {
      setFalse: closePaidComputeConfirmationDialog,
      setTrue: openPaidComputeConfirmationDialog,
    },
  ] = useToggleState(false);
  const [pendingKernelSize, setPendingKernelSize] = useState<KernelSize | null>(
    null,
  );

  const handleConfirmPaidCompute = useCallback(() => {
    if (pendingKernelSize) {
      onSelect(pendingKernelSize);
      closePaidComputeConfirmationDialog();
    }
  }, [onSelect, pendingKernelSize, closePaidComputeConfirmationDialog]);

  const { data: userConfirmedPaidComputeData } =
    useUserConfirmedPaidComputeQuery({});
  const userConfirmedPaidComputeForever =
    userConfirmedPaidComputeData?.me?.confirmedPaidCompute ?? false;
  const onSelectInternal = useCallback(
    (newKernelSize: KernelSize) => {
      const isPaidCompute =
        AdvancedComputeKernelOptionsUnionLiteral.guard(newKernelSize);
      if (isPaidCompute && !userConfirmedPaidComputeForever) {
        setPendingKernelSize(newKernelSize);
        openPaidComputeConfirmationDialog();
      } else {
        onSelect(newKernelSize);
      }
    },
    [
      onSelect,
      openPaidComputeConfirmationDialog,
      userConfirmedPaidComputeForever,
    ],
  );

  const {
    data,
    error: error_,
    loading,
    refetch,
  } = useGetKernelSizesForPickerQuery({});

  useEffect(() => {
    void refetch();
  }, [
    advancedComputeProfilesToShowString,
    advancedComputeProfilesToEnableString,
    kernelPricesString,
    refetch,
  ]);

  const maybeDefaultKernelSizeResult = KernelSize.validate(
    data?.getKernelSizesV2?.default,
  );

  const defaultSize = maybeDefaultKernelSizeResult.success
    ? maybeDefaultKernelSizeResult.value
    : null;

  const kernelSizesOrEmpty = data?.getKernelSizesV2?.options ?? [];

  const currentUser = useCurrentUser();
  const userIsAdmin = currentUser?.orgRole === OrgRole.ADMIN;

  const getTooltipIfKernelSizeDisabled = (
    kernelSize: KernelSizeConfigV2,
  ): false | JSX.Element => {
    if (kernelSize.disabled && !disabled) {
      if (isSignedEmbeddedProject) {
        return (
          <HexTooltip content="Paid compute profiles are disabled for signed embedded projects">
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (advancedComputeProfilesToShow.includes(kernelSize.id)) {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute is available in Beta.{" "}
                {userIsAdmin ? (
                  <>
                    <Link
                      href="https://hextech.typeform.com/to/N39yVwmZ"
                      target="_blank"
                    >
                      Request access.
                    </Link>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <ContactAnAdmin text="Contact your admin" /> to request
                    access.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (!data?.getKernelSizesV2?.isFreeTrial) {
        return (
          <FeatureGateToolTip
            content="to use larger kernels"
            featureGate="maxKernelSizes"
          >
            <FeatureGatePill />
          </FeatureGateToolTip>
        );
      } else {
        return (
          <HexTooltip
            content={
              <>
                We limit the maximum kernels size on free trials. You can{" "}
                <Link href="mailto:sales@hex.tech?subject=Requesting removal of kernel size limit">
                  reach out to us
                </Link>{" "}
                to request larger sizes.
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      }
    }
    // don't return a tooltip if the kernel size is not disabled
    return false;
  };

  const error = !!error_ || kernelSizesOrEmpty.length === 0;

  return (
    <>
      <Picker
        disabled={disabled}
        error={error}
        items={[...kernelSizesOrEmpty]
          // sort first by GPUs and then Memory
          .sort(
            (a, b) => b.gpuLimit - a.gpuLimit || b.memoryLimit - a.memoryLimit,
          )
          .map((kernelSize) => ({
            ...kernelSize,
            disabled: isSignedEmbeddedProject
              ? kernelPrices[kernelSize.id]
                ? true // We want to disable paid compute profiles for signed embedding projects
                : kernelSize.disabled
              : kernelSize.disabled,
          }))
          .map((kernelSize) => ({
            title: kernelPrices[kernelSize.id]
              ? `${kernelSize.humanName} (${kernelPrices[kernelSize.id]})` // show price if available for kernel size
              : kernelSize.humanName,
            disabled: kernelSize.disabled,
            selectedItemIntent: kernelPrices[kernelSize.id]
              ? Intent.WARNING
              : undefined, // show with Intent.WARNING when selected only if kernel size costs money
            rightElement: getTooltipIfKernelSizeDisabled(kernelSize),
            description: (
              <Description>
                <div>{filesize(kernelSize.memoryLimit)} memory</div>
                <div>
                  {kernelSize.cpuLimit === 1
                    ? `${kernelSize.cpuLimit} CPU`
                    : `${kernelSize.cpuLimit} CPUs`}
                </div>
                {kernelSize.gpuLimit > 0 ? (
                  <div>
                    {kernelSize.gpuLimit === 1
                      ? `${kernelSize.gpuLimit} GPU`
                      : `${kernelSize.gpuLimit} GPUs`}
                  </div>
                ) : null}
              </Description>
            ),
            key: kernelSize.id,
          }))}
        label={!hideLabel ? "Compute profile" : ""}
        loading={loading}
        selectedItem={currentKernelSize ?? defaultSize}
        smallPicker={smallPicker}
        onSelect={onSelectInternal}
      />
      {KernelSize.guard(pendingKernelSize) && (
        <PaidComputeConfirmationDialog
          isOpen={paidComputeConfirmationDialogOpen}
          kernelSize={pendingKernelSize}
          onClose={closePaidComputeConfirmationDialog}
          onConfirm={handleConfirmPaidCompute}
        />
      )}
    </>
  );
};
